<template>
<div>
<Modal ref='AddTimeModal'>
    <div slot='header'>Add time</div>
    <div slot='content' >
        <form class='filter-form'>
            <!-- Clock in date -->
            <div class="form-row" style='margin-top: 0'>
                <label>Clock in:</label>
                <input ref='in_datepicker' class='form-control' type='text' placeholder="Date" 
                    v-bind:class="{'form-control-error': $v.editingDate.in_date.$error }" >
                <input class='form-control' type='number' min='0' max='23' placeholder="HH" v-model.number='$v.editingDate.in_hour.$model'
                    v-bind:class="{'form-control-error': $v.editingDate.in_hour.$error }" size='3'>: 
                <input class='form-control' type='number' min='0' max='59' placeholder="MM" v-model.number='$v.editingDate.in_minute.$model'
                    v-bind:class="{'form-control-error': $v.editingDate.in_minute.$error }"  size='3'>
            </div>
            <div v-if='$v.editingDate.in_date.$dirty'>
                <div class="error-msg" v-if='!$v.editingDate.in_date.required'>Date is required</div>
            </div>
            <div v-if='$v.editingDate.in_hour.$dirty'>
                <div class="error-msg" v-if='!$v.editingDate.in_hour.required'>Hour is required</div>
                <div class="error-msg" v-if='!$v.editingDate.in_hour.between'>Hour should be between 0 and 23</div>
            </div>
            <div v-if='$v.editingDate.in_minute.$dirty'>
                <div class="error-msg" v-if='!$v.editingDate.in_minute.required'>Minute is required</div>
                <div class="error-msg" v-if='!$v.editingDate.in_minute.between'>Minute should be between 0 and 59</div>
            </div>

            <!-- Clock out date -->
            <div class="form-control">
                <input type="checkbox" id="hasClockOutTimeCheckbox" v-model="hasClockoutTime">
                <label for="hasClockOutTimeCheckbox">Add clock out time</label>
            </div>

            <div v-show="hasClockoutTime">
                <div class="form-row" style='margin-top: 0'>
                    <label>Clock out:</label>
                    <input ref='out_datepicker' class='form-control' type='text' placeholder="Date" 
                        v-bind:class="{'form-control-error': $v.editingDate.out_date.$error }" >
                    <input class='form-control' type='number' min='0' max='23' placeholder="HH" v-model.number='$v.editingDate.out_hour.$model'
                        v-bind:class="{'form-control-error': $v.editingDate.out_hour.$error }" size='3'>: 
                    <input class='form-control' type='number' min='0' max='59' placeholder="MM" v-model.number='$v.editingDate.out_minute.$model'
                        v-bind:class="{'form-control-error': $v.editingDate.out_minute.$error }"  size='3'>
                </div>
                <div v-if='$v.editingDate.out_date.$dirty'>
                    <div class="error-msg" v-if='!$v.editingDate.out_date.required'>Date is required</div>
                </div>
                <div v-if='$v.editingDate.out_hour.$dirty'>
                    <div class="error-msg" v-if='!$v.editingDate.out_hour.required'>Hour is required</div>
                    <div class="error-msg" v-if='!$v.editingDate.out_hour.between'>Hour should be between 0 and 23</div>
                </div>
                <div v-if='$v.editingDate.out_minute.$dirty'>
                    <div class="error-msg" v-if='!$v.editingDate.out_minute.required'>Minute is required</div>
                    <div class="error-msg" v-if='!$v.editingDate.out_minute.between'>Minute should be between 0 and 59</div>
                </div>
            </div>

            <!-- Comment -->
            <div class="form-row" style='margin-top: 0'>
                <label>Reason for change: </label>
                <textarea class='form-control' placeholder="Enter your reason for adding the time" v-model.trim='$v.editingDate.comment.$model'
                    v-bind:class="{'form-control-error': $v.editingDate.comment.$error }" >
                </textarea>
            </div>

            <div v-if='$v.editingDate.comment.$dirty'>
                <div class="error-msg" v-if='!$v.editingDate.comment.required'>Comment is required</div>
                <div class="error-msg" v-if='!$v.editingDate.comment.minLength'>Comment should be at least 5 characters long</div>
            </div>
            
        </form>
    </div>
    <div slot='footer' style='display: flex'>
        <div class='btn btn-primary' v-on:click='saveTime()' style='margin-right: 15px'>Save</div>
        <div class='btn btn-white' v-on:click="$refs.AddTimeModal.close()">Cancel</div>
    </div>
</Modal>

<StatusModal ref='StatusModal'
    :message='saveStatus'
    :isLoading='saving'>
</StatusModal>

</div>
</template>

<script>

import { required, between, minLength } from 'vuelidate/lib/validators';
import Modal from '../../../shared/modal/Modal';
import flatpickr from 'flatpickr';
import { DateTime } from 'luxon';
import EmployeeService from '../../../employees/EmployeeService';
import StatusModal from '../../../shared/modal/StatusModal';

export default {
    
    props: [ 'user' ],

    components: {
        Modal, StatusModal
    },

    data() {
        return {
            editingDate: {
                in_date: 0,
                in_hour: 0,
                in_minute: 0,
                out_date: 0,
                out_hour: 0,
                out_minute: 0,
                comment: '',
            },

            employeeService: new EmployeeService(),

            saving: false,
            saveStatus: '',

            hasClockoutTime: false,
        }
    },

    validations: {
        editingDate: {
            in_date: { required },
            in_hour: { required, between: between( 0, 23 ) },
            in_minute: { required, between: between( 0, 59 ) },
            out_date: {  },
            out_hour: { between: between( 0, 23 ) },
            out_minute: { between: between( 0, 59 ) },
            comment: { required, minLength: minLength( 5 ) }
        }
    },

    methods: {
        show() {
            
            var clock_in = DateTime.local();
            var clock_out = DateTime.local();
            this.editingDate.in_date = clock_in;
            this.editingDate.out_date = clock_out;

            this.$refs.AddTimeModal.show();

            setTimeout( () => {
                flatpickr( this.$refs.in_datepicker, {
                    dateFormat: 'm/d/Y',
                    onChange: ( dates ) => {
                        this.editingDate.in_date = DateTime.fromJSDate( dates[0] );
                    },
                    defaultDate: this.editingDate.in_date.toJSDate()
                } );
                flatpickr( this.$refs.out_datepicker, {
                    dateFormat: 'm/d/Y',
                    onChange: ( dates ) => {
                        this.editingDate.out_date = DateTime.fromJSDate( dates[0] );
                    },
                    defaultDate: this.editingDate.out_date.toJSDate()
                } );
            })
        },

        saveTime() {
            
            this.$v.$touch()
            if ( this.$v.$invalid ) {
                return;
            }

            this.saving = true;
            this.saveStatus = "Saving timecard...";
            this.$refs.AddTimeModal.close();
            this.$refs.StatusModal.show();

            let data = {
                clock_in: this.editingDate.in_date.set({
                    hour: this.editingDate.in_hour,
                    minute: this.editingDate.in_minute
                }),
                user_id: this.user.id,
                comment: this.editingDate.comment
            }
            
            if ( this.hasClockoutTime ) {
                data.clock_out = this.editingDate.out_date.set({
                    hour: this.editingDate.out_hour,
                    minute: this.editingDate.out_minute
                })
            }

            this.employeeService.createUserTimecard( data ).then(
                _ => {
                    this.saveStatus = "Saved";
                    this.$emit( 'saved' );
                },
                error => {
                    this.saveStatus = error.body.message || "Error. Make sure all the fields are correct and try again";
                }
            ).finally( () => this.saving = false );
        },
    },


}
</script>


<style>

</style>