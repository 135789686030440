<template>
<div class='box'>

    <StatusModal ref='StatusModal'
        :message='saveMessage'
        :isLoading='saveLoading'></StatusModal>

    <div class="box-header">
        Memos
    </div>
    <div class='box-content content-padding'>
        <div v-if='user.deleted_at == null'>
            <form class='filter-form' id='addDaysForm'>
                <div class='form-row'>
                    <label>Add memo: </label>
                    <textarea class='form-control' rows='1' placeholder='Write a new memo here' v-model='$v.memoContent.$model'></textarea>
                </div>
                <div v-if='$v.memoContent.$dirty'>
                    <div class="error-msg" v-if='!$v.memoContent.required'>Please type the content of the memo.</div>
                    <div class="error-msg" v-if='!$v.memoContent.minLength'>Memo must be at least 5 characters long.</div>
                    <div class="error-msg" v-if='!$v.memoContent.maxLength'>Memo must not exceed 500 characters.</div>
                </div>

                <div class='form-row'>
                    <div class='btn btn-primary' v-on:click='addMemo'>Save memo</div>
                </div>
            </form>
        </div>
        <div v-if='!isLoading'>
            <div class='table'>
            <table class='table table-hover mb-3'>
                <thead>
                    <th>Memo</th>
                    <th>Created by</th>
                    <th>Date</th>
                </thead>
                <tbody>
                <tr v-for='memo in employeeMemos' :key='memo.id'>
                    <td>{{ memo.content }}</td>
                    <td>{{ memo.creator.name }}</td>
                    <td>{{ DateTime.fromISO( memo.created_at ).toLocaleString( DateTime.DATETIME_MED ) }}</td>
                </tr>
                </tbody>
            </table>
            <div>
                <span class='btn btn-white' v-on:click='download'>Export to Excel</span>
            </div>
        </div>
    </div>

    <div v-if='isLoading'>
        <BarLoader></BarLoader>
    </div>
  </div>

</div>
</template>

<script>
import BarLoader from '../loaders/bar/BarLoader.vue';
import StatusModal from '../modal/StatusModal';
import EmployeeMemoService from './EmployeeMemoService';
import DocumentDownloader from '../document-downloader/DocumentDownloader';
import _ from 'lodash';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import AuthMixin from '../../../../mixins/AuthMixin';
import PasswordConfirmModal from '../modal/PasswordConfirmModal';
import Modal from '../modal/Modal';
import { DateTime } from 'luxon';

export default {
    name: "EmployeeMemoManager",
    props: [ 'user' ],
    components: {
        BarLoader,
        StatusModal,
        PasswordConfirmModal,
        Modal
    },
    mixins: [ AuthMixin ],

    data() {
        return {
            user_id: null,
            memoService: new EmployeeMemoService(),
            isLoading: true,
            employeeMemos: [],

            memoContent: '',

            saveMessage: '',
            saveLoading: false,
            DateTime: DateTime,
        }
    },
    validations: {
        memoContent: {
            required,
            minLength: minLength( 5 ),
            maxLength: maxLength( 500 ),
        }
    },
    methods: {
        addMemo() {

            this.$v.$touch();
            if ( this.$v.$invalid ) {
                return;
            }

            this.saveLoading = true;
            this.saveMessage = "Saving memo...";
            this.$refs.StatusModal.show();

            this.memoService.addMemo( this.user_id, this.memoContent )
            .then(
                response => {
                    this.saveMessage = 'Saved';
                    this.memoContent = '';
                    this.$v.$reset()
                },
                _ => {
                    this.saveMessage = 'Error saving the new memo.';
                }
            )
            .finally( () => {
                this.saveLoading = false;
                this.getEmployeeMemos();
            })
        },
        getEmployeeMemos() {
            
            this.isLoading = true;

            this.memoService.getMemos( this.user_id )
            .then(
                response => {
                    this.employeeMemos = response.body.data;
                    this.isLoading = false;
                },
            )
        },
        download() {
            let downloader = new DocumentDownloader();
            downloader.getEmployeeMemos( this.user.id )
        }
    },
    created() {
        this.user_id = this.user.id;
        this.getEmployeeMemos();
    },
    computed: {
        
    }
}
</script>

<style scoped>
.table {
    margin-bottom: 18px;
}
</style>
