import Vue from 'vue';

export default class {

    getMemos( user_id ) {
        return Vue.http.get( `memos/${user_id}` )
    }

    addMemo( user_id, content ) {
        return Vue.http.post( `memos/${user_id}`, { content } ) 
    }

    updateMemo( user_id, memo_id, content ) {
        return Vue.http.patch( `memos/${user_id}/${memo_id}`, { content } )
    }
}