<template src='./ViewEmployee.html'></template>

<script>
import EmployeeService from '../EmployeeService.js';
import EmployeeEditor from '../editor/EmployeeEditor.vue';
import BarLoader from '../../shared/loaders/bar/BarLoader.vue';
import WeekPicker from '../../shared/weekpicker/WeekPicker.vue';
import LeaveDaysManager from '../../shared/leave-days-manager/LeaveDaysManager.vue';
import { DateTime } from 'luxon';
import Timecard from '../../shared/timecard/Timecard.vue';
import EmployeeMemoManager from '../../shared/employee-memo-manager/EmployeeMemoManager.vue';

export default {
    name: 'ViewEmployee',
    components: {
        EmployeeEditor,
        BarLoader,
        WeekPicker,
        LeaveDaysManager,
        Timecard,
        EmployeeMemoManager,
    },
    data() {
        return {
            user: null,
            id: this.$route.params['id'],
            employeeService: new EmployeeService(),

            undeleteLoading: false,
        }
    },
    methods: {
        getUser() {
            this.employeeService.getUser( this.id ).then(
                response => this.user = response.body.data,
                error => {
                    if ( error.status === 404 ) {
                        this.$router.push({ name: '404' });
                    }
                    else if ( error.status === 403 ) {
                        this.$router.push({ name: '403' })
                    }
                }
            );
        },

        undeleteUser() {
            this.undeleteLoading = true;
            this.employeeService.undeleteUser( this.user.id ).then(
                _ => {
                    // Refresh this page
                    this.$router.go();
                }
            )
        }
        
        
    },

    computed: {
        deleted_at() {
            if ( this.user.deleted_at == null ) return '-';
            return DateTime.fromISO( this.user.deleted_at ).toFormat( 'LLLL d, y (LL/dd/y)' )
        }
    },
    created() {
        this.getUser();
    },
}

</script>


<style lang="less" scoped>

#deletedUserBox {
    background: linear-gradient( 125deg, #ef5350, #EC407A );
    color: white;
}

</style>

